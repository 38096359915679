import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Image from "gatsby-image"
import useContentfulImage from "../../hooks/useContentfulImage"

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const fluid = useContentfulImage(node.data.target.sys.contentful_id)
      return <Image fluid={fluid} />
    },
  },
}

export default ({ richTextJson }) =>
  documentToReactComponents(richTextJson, options)
